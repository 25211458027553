<template>
  <div class="classifyList" :style="{ backgroundColor: bgcolor }">
    <div class="classList">
      <div
        @click="toDetail(item)"
        :class="{
          checkSty: checkindex === item.menuId,
        }"
        v-for="(item, index) in toptype"
        :key="index"
        class="classItem"
      >
        <div>
          <span>{{ item.ancestors || item.menuName }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    checkindex: {
      type: Number,
      default: -1,
    },
    bgcolor: {
      type: String,
      default: "#d81722",
    },
    toptype: {
      type: Array,
    },
  },

  methods: {
    // 点击事件
    toDetail(item) {
      console.log("item",item)
      switch (item.menuId) {
        case 43:
        case 44:
        case 45:
          this.$router.push({
            path: "/levelFivePage",
            query: { menuId: item.menuId, checkindex: item.menuId },
          });
          break;
        case 48:
          this.$router.push({
            path: "/leveThreePageZXKT",
            query: { menuId: item.menuId },
          });
          break;
        case 49:
          this.$router.push({
            path: "/leveThreePageZXKT2",
            query: { menuId: item.menuId },
          });
          break;
        case 51:
        case 52:
        case 53:
          this.$router.push({
            path: "/levelFivePage",
            query: { menuId: item.menuId,checkindex: item.menuId },
          });
          break
        case 184:
          this.$router.push({
            path: "/levelFivePagePPJS",
            query: { menuId: item.menuId, checkindex: item.menuId },
          });
          break;
        case 185:
          this.$router.push({
            path: "/levelFivePagePPJS",
            query: { menuId: 218, checkindex: item.menuId },
          });
          break;
        case 186:
          this.$router.push({
            path: "/levelFivePagePPJS",
            query: { menuId: 219, checkindex: item.menuId },
          });
          break;
        case 187:
          this.$router.push({
            path: "/levelFivePagePPJS",
            query: { menuId: item.menuId, checkindex: item.menuId },
          });
          break;
        case 228:
          this.$router.push({
            path: "/levelFivePagePPJS",
            query: { menuId: item.menuId, checkindex: item.menuId },
          });
          break;
        default:
          this.$router.push({
            path: "/levelThreePage",
            query: { menuId: item.menuId },
          });
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.classifyList {
  background-color: #d81722;
  font-size: 3vw;
  color: #fff;
  width: 100%;
  height: 6vh;
  .classList {
    text-align: center;
    display: flex;
    align-items: center;
    height: 100%;
    font-family: 'FZZZH',serif;
    :not(:nth-last-child(1)) {
      border-right: 1px solid #fff;
    }
    .classItem {
      width: 24.7%;
      height: 60%;

      display: flex;
      justify-content: center;
      align-items: center;
      div {
        /*height: 4vh;*/
        width: 85%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
      }
    }
  }
}
.checkSty {
  div {
    background-color: #e27379;
    display: flex;
    align-items: flex-start !important;
    padding: 6px 0;
  }
}
.checkSty1 {
  div {
    background-color: rgb(97, 38, 236) !important;
  }
}
</style>
