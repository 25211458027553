<template>
  <!-- 搜素、标签 -->
  <div class="retrieve">
    <div class="serch">
      <van-search
        shape="round"
        input-align="center"
        v-model="value1"
        left-icon=""
        :disabled="isDisabled"
        :placeholder="placeholderText"
        @clear="$emit('clear')"
      >
        <template #right-icon>
          <!--  -->
          <div @click="$emit('searchName', value1)">
            <van-icon
              size="22px"
              :color="!isDisabled ? '#000000' : '#999999'"
              name="search"
            />
          </div>
        </template>
      </van-search>
    </div>
  </div>
</template>

<script>
import { searchProductByName } from "@/api/path";

export default {
  props: {
    isDisabled: {
      type: Boolean,
      default: true,
    },
    placeholderText: {
      type: String,
      default: "本页面无可搜索内容",
    },
  },
  data() {
    return {
      value1: "",
      current: 0,
      size: 5,
    };
  },
  
  methods: {
    // searchName(value1) {
    //   this.$emit("searchName", res);
    //   // let data = {
    //   //   current: this.current,
    //   //   size: this.size,
    //   //   name: this.value1,
    //   // };
    //   // searchProductByName(data).then((res) => {
    //   // });
    // },
  },
};
</script>

<style lang="scss" scoped>
.retrieve {
  height: 7%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  .serch {
    width: 90%;
    /deep/ .van-search {
      padding: 0;
      background-color: transparent;
      .van-search__content {
        background-color: #f0f0f0;
      }
      .van-cell {
        padding: 3px 8px 3px 0;
      }
      .van-search__content--round {
        border-radius: 10px;
      }
    }
  }
}
</style>