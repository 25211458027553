<template>
  <!-- 应用领域二级菜单 -->
  <div class="field">
    <!-- 顶部 -->
    <swipeTop
      :top_img="top_img"
      :ZHtitle="ZHtitle"
      :ENtitle="ENtitle"
    ></swipeTop>
    <!-- 搜索框 -->
    <seachVue></seachVue>
    <!-- 标签 -->
    <classifyList :toptype="toptype" :checkindex="checkindex"></classifyList>
    <!-- 内容 应用领域 -->
    <div class="category">
      <!-- 业务分类 -->
      <div class="categoryContent">
        <div class="categoryTitle titleOne">
          <van-icon size="8" style="transform: rotate(180deg)" name="play" />
          业务分类
          <van-icon size="8" name="play" />
        </div>
        <div class="categoryItem">
          <div
            v-for="(item, index) in subMenuList"
            :key="index"
            @click="toDetail(item)"
            class="item"
          >
            <img :src="item.pic" alt="" />
            {{ item.menuName }}
          </div>
        </div>
      </div>
      <!-- 疾病分类 -->
      <div class="categoryContent">
        <div class="categoryTitle titleTwo">
          <van-icon size="8" style="transform: rotate(180deg)" name="play" />
          疾病分类
          <van-icon size="8" name="play" />
        </div>
        <div class="categoryItem">
          <div @click="toChild(1)" class="item" style="z-index: 1">
            <img src="../../assets/img/icon1.png" />
            感染性疾病
          </div>
          <div @click="toChild(2)" class="item">
            <img src="../../assets/img/icon2.png" />
            过敏性疾病
          </div>
          <div class="item1">
            <div @click="toChild(3)">
              微生物检测
              <span class="line"></span>
            </div>
            <div @click="toChild(4)">
              PCT检测
              <span style="left: 28%" class="line"></span>
            </div>
          </div>
          <div @click="toChild(5)" class="item">
            <img src="../../assets/img/icon3.png" />
            移植诊断
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- </div> -->
</template>
<script>
import swipeTop from "@/components/swipeTop.vue";
import seachVue from "@/components/seachVue.vue";
import { genMenuInfo } from "@/api/path";
import classifyList from "@/components/classifyList.vue";
export default {
  components: {
    swipeTop,
    seachVue,
    classifyList,
  },
  data() {
    return {
      menuId: this.$route.query.mid,
      top_img: "",
      ZHtitle: "",
      ENtitle: "",
      subMenuList: [],
      nextList: [],
      toptype: [],
      checkindex: Number(this.$route.query.checkindex),
    };
  },
  mounted() {
    // 监听返回事件
    if (window.history && window.history.pushState) {
      history.pushState(null, null, document.URL);
      window.addEventListener("popstate", this.backButton, false);
    }
  },
  created() {
    this.getAllMenu();
  },
  methods: {
    //获取类别数据
    getAllMenu() {
      genMenuInfo(this.menuId)
        .then((res) => {
          this.ZHtitle = res.data.menuName; // 获取中文标题
          this.ENtitle = res.data.menuEnglish; // 获取英文标题
          this.top_img = res.data.background; // 头部背景
          this.subMenuList = res.data.subMenuList;
          this.toptype = res.data.classificationMessageAlways;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 前往下级分类
    toDetail(item) {
      this.$router.push({
        path: "/levelThreePage",
        query: { menuId: item.menuId },
      });
    },
    toChild(index) {
      // this.$router.push({
      //   path: "/levelThreePage",
      //   query: { menuId: index },
      // });
      this.$toast({
        message: "数据更新中...",
      });
    },
    // 返回事件处理
    backButton() {
      this.$router.push({
        path: "/",
      });
    },
  },
  destroyed() {
    // 销毁页面返回事件
    window.removeEventListener("popstate", this.backButton, false);
  },
};
</script>

<style lang="scss" scoped>
.field {
  height: 100vh;
  overflow: hidden;
}
.category {
  width: 100%;
  height: 62%;
  background-color: #9b0a11;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  .categoryContent {
    height: 40vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    .categoryTitle {
      text-align: center;
      width: 100%;
      height: 10vw;
      line-height: 10vw;
      color: white;
      font-size: 0.9rem;
      font-weight: bolder;
      letter-spacing: 2px;
      background-color: #ac2126;
    }
    .categoryItem {
      width: 82%;
      display: flex;
      flex-flow: column;
      flex-wrap: wrap;
      justify-content: space-around;
      align-content: space-around;
      overflow: hidden;
      padding: 10px 0;
      .item {
        background-color: #b25357;
        width: 45%;
        height: 14.5vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: white;
        font-size: 3vw;
        justify-content: center;
        border-radius: 15px;
        text-align: center;
        img {
          width: 20%;
          margin-bottom: 13px;
        }
        span {
          width: 80%;
        }
      }
    }
  }
}
.titleOne {
  background-image: url("../../assets/img/listbg.png");
}
.titleTwo {
  background-image: url("../../assets/img/listbg1.png");
}
.item1 {
  height: 14.5vh;
  width: 54%;
  background-color: #9b0a11;
  display: flex;
  flex-direction: column;
  color: #fff;
  font-size: 3vw;
  margin-left: -55px;
  :nth-child(1) {
    border-radius: 20px 10px 10px 0;
    background-color: #bf545a;
    line-height: 7vh;
    z-index: 2;
    border-bottom: 0.05em solid #99403c;
  }
  :nth-child(2) {
    border-radius: 0 10px 10px 20px;
    background-color: #bf545a;
    line-height: 7vh;
    z-index: 2;
  }
  div {
    height: 50%;
    text-align: center;
  }
  .line {
    display: inline-block;
    width: 8px;
    height: 20px;
    position: relative;
    left: 25%;
    background-color: rgb(237, 237, 238);
    opacity: 0.5;
  }
}
/deep/ .retrieve {
  background-color: #ffffff;
}
/deep/ .van-search__content {
  background-color: #f1f1f1 !important;
}
.apea {
  height: 62%;
  background-color: #9b0a12;
  padding: 8px 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  overflow: scroll;
  .apeaItem {
    background-color: hsl(357, 46%, 54%);
    border: 1px solid #bf545a;
    border-radius: 10px;
    width: 29%;
    height: 25%;
    margin: 5px 13px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 3vw;
    span {
      width: 80%;
      margin-top: 10px;
      color: white;
      text-align: center;
    }
  }
}
</style>
<style>
.van-empty__description {
  color: white !important;
}
.custom-image .van-empty__image {
  width: 90px;
  height: 90px;
}
</style>
